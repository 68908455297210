import React from 'react';

import styles from './CVMainPage.module.css';

function CVMainPage() {

    return(
        <div className={styles.CVMain} >

        <h1>I'm Dan.</h1>
        <h2>I am a Junior Web Developer.</h2>

        </div>
    )

}

export default CVMainPage;
