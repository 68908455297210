import React from 'react';
import emailjs from 'emailjs-com';

import styles from './ContactMe.module.css';
import Banner from '../../components/UI/Banner/Banner';

export default function ContactUs() {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('gmail', 'Contact_Me', e.target, 'user_zcdqia8r8AkRD0h6ngItG')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  }

  return (
      <div className={styles.ContactMe}>
      <Banner name='Contact Me'/>
    <form className={styles.ContactMeForm} onSubmit={sendEmail}>
    <h2>Please fill in the below</h2>
      <div className={styles.ContactMe_Name} >
      <label>Name:</label>
      <input type="text" name="name" />
      </div>
      <div>
       <label>Email:</label>
      <input type="email" name="email" />   
      </div>
      
      <label>Message</label>
      <textarea name="message" rows='5' cols='60' />
      <button type='submit'>Send</button>
      
    </form>
      </div>
    
  );
}