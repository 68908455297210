import React from 'react';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
// import {Route, Switch} from 'react-router-dom';

// Component Import

import Layout from '../HOC/Layout/Layout';


import styles from './App.module.css';

function App() {

  library.add(fab,faEnvelope);

  return (
    <div className={styles.App}>
    
     <Layout/> 
        
      
    </div>
  );
}

export default App;
