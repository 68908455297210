const project  = [

   {
        name: 'Wedding',
        info: 'I created a website for our wedding',
        lang: 'HTML, CSS and JavaScript',        
        gitHub: 'https://github.com/dangoodfellow86/Wedding'
    },
    {
        name: 'St Marys Convent',
        info: 'This was a free website that i created for a local care home to assist in the online presance',
        lang: 'HTML and CSS',
        link: 'www.stmarysebchester.com',
        gitHub: 'https://github.com/dangoodfellow86/StMarysConvent'
    },
    {
        name: 'CV',
        info: 'This was my portfolio website that i orginally created with HTML and CSS but have since upgrade to Reactjs',
        lang: 'Reactjs with react-router-dom',
        link: 'www.danielgoodfellow.co.uk',
        gitHub: 'https://github.com/dangoodfellow86/CV'
    }
]

export default project;