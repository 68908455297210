import React from 'react'
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';

import * as CgIcons from 'react-icons/cg';




export const NavItemsData = [
    {
        title: 'Home',
        path: '/',
        icon: <AiIcons.AiFillHome/>
        
    },
    {
        title: 'Projects',
        path: '/Projects',
        icon: <AiIcons.AiOutlineProject/>
        
    },
    {
        title: 'About Me',
        path: '/About-Me',
        icon: <CgIcons.CgProfile/>
        
    },
    {
        title: 'Contact Me',
        path: '/Contact-Me',
        icon: <FaIcons.FaEnvelopeOpenText/>
        
    }

]

