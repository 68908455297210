import React, {useState} from 'react'
import * as FaIcons from "react-icons/fa"
import * as AiIcons from "react-icons/ai"
import {IconContext} from 'react-icons'
import styles from './SideBar.module.css';
import {Link} from 'react-router-dom'
import {NavItemsData} from '../Navigation/NavItemsData'


function SideBar() {
    const [sidebar, setSidebar] = useState(false)

    const showSidebar = () => setSidebar(!sidebar)

    const showSidebarStyles = sidebar ? styles.NavMenuActive : styles.NavMenu;

    return (
        <> 
     <IconContext.Provider value={{color:'#fff'}}> 
        <div className={styles.navBar}>
        <Link to="#" className={styles.menuBars}>
        <FaIcons.FaBars onClick={showSidebar}/>
        </Link>
        </div>
        <nav className={showSidebarStyles}>
            <ul className={styles.navMenuItems} onClick={showSidebar}>
            <li className={styles.navBarToggle}>
                <Link to='#' className={styles.menuBars}>
                  <AiIcons.AiOutlineClose/>  
                </Link>
            </li>
                {NavItemsData.map((item, index) => {
                    return (
                        <li key={index} className={styles.NavText} >
                            <Link to={item.path} >
                                {item.icon}
                                <span>{item.title}</span>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </nav>
    </IconContext.Provider>
    </>
    )
}

export default SideBar;
