import React from 'react'
import SideBar from '../../SideBar/SideBar';

// Component Import

import NavigationItem from './NavigationItem/NavigationItem';

//Styles Import

import styles from './NavigationItems.module.css';

function NavigationItems(){
    return(
        <header className={styles.NavBar} >
            <div>
            <SideBar/>
            <NavigationItem
                name='Home'
                link='/'
            />
            <NavigationItem
                name='Projects'
                link='/Projects'
            />
            <NavigationItem
                name='About Me'
                link='/About-Me'
            />
            <NavigationItem
                name='Contact Me'
                link='/Contact-Me'
            />
        
            
            </div>
            
        </header>

    )
}
export default NavigationItems;