import React from 'react';

import styles from './Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function Footer() {

    const copyRight = new Date().getFullYear();

    return(
        <div className={styles.Footer} >
            <h5>Designed & Developed by Dan Goodfellow {copyRight} </h5>
        <div className={styles.Icons} >
            <a href={'https://www.linkedin.com/in/danieljgoodfellow/'}><FontAwesomeIcon icon={["fab","linkedin"]} size={'lg'}/></a>
            <a href={"mailto:daniel.goodfellow@sky.com"} ><FontAwesomeIcon icon={'envelope'} size={'lg'}/></a>
            
            
        </div>
        </div>
    )
}
export default Footer;